import React from 'react'
import { PrimaryButton } from '../UI/PrimaryButton'
import { WhiteButton } from '../UI/WhiteButton'

export const ProductBuyVisit = ({ cover, url }) => {
    return (
        <div 
            className = ' d-flex justify-content-center align-items-center '
            style={{
                backgroundImage: `url(${cover})`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                height: '100vh',
            }}
        >
            <div className = ' detail_visit-container d-flex flex-column '>
                <div>
                    <h1 className = ' section-title ' style = {{ color: 'white'}} >¡ADQUIERELA LA TUYA!</h1>
                    <p style = {{ color: 'white' }}>Visita una de nuestras agencias o compra directo desde la página.</p>
                </div>
                <div className = ' d-flex justify-content-center '>
                    <PrimaryButton 
                        text = 'COMPRAR'
                        link = { url }
                        external = { true }
                    />
                    <div className = ' px-2 ' />
                    <WhiteButton 
                        text = 'AGENDAR VISITA'
                        link = 'https://m.me/motoelectrics.mx'
                        external = { true }
                    />
                </div>
            </div>
        </div>
    )
}
