import React from 'react';
import {
    BrowserRouter as Router,
    Routes,
    Route,
  } from "react-router-dom";

import { NavbarComponent } from '../components/NavbarComponent';
import { Home } from '../pages/Home';
import { Detail } from '../pages/Detail';
import { ReviewsPage } from '../pages/ReviewsPage';
import { Footer } from '../components/Footer';
import { Contact } from '../pages/Contact';

export const AppRouter = () => {
    return (
        <Router>
            <div style = {{ overflow: 'auto' }}>
                <NavbarComponent />

                <Routes>
                    <Route path = "/" element = { <Home /> } />
                    <Route path = "/inicio" element = { <Home /> }/>
                    <Route path = "/producto/:product" element = { <Detail /> } />
                    <Route path = "/testimonios" element = { <ReviewsPage /> }/>
                    <Route path = '/contacto' element = { <Contact /> }/>
                </Routes>

                <Footer />
            </div>
        </Router>
    )
}
