import React from 'react';
import { CatalogLink } from '../components/CatalogLink';
import { HomeAccessories } from '../components/Home/HomeAccessories';
import { HomeElectricMobility } from '../components/Home/HomeElectricMobility';
import { HomeHeader } from '../components/Home/HomeHeader';
import { HomeProducts } from '../components/Home/HomeProducts';
import { SeasonModal } from '../components/SeasonModal';
import { YoutubeLink } from '../components/YoutubeLink';

import headerImage from './../assets/home/background-cover.jpeg';

export const Home = () => {
    return (
        <>
            <SeasonModal />
            <HomeHeader image = { headerImage } />
            <HomeProducts />
            <HomeAccessories />
            <HomeElectricMobility />
            <YoutubeLink />
            <CatalogLink />
        </>
    )
}
