
//* Preview images.
import { blackRacing3000, blackRacing5000, whiteRacing, st2000, st1000, electricDelivery, xiaomiEssentials, comingSoon } from './images-imports-bikes';


export const products = {
    "black-racing-3000": {
        bikeInfo: 'https://motoelectrics.com/bike-info/Black%20Racing.pdf',
        brand: 'Sinotech',
        buyLink: 'https://motoelectrics.com/tienda/producto/black-racing-3000w/',
        colors: ['#000000', '#666666'],
        cover: blackRacing3000.cover,
        gallery: [ 
            blackRacing3000.gallery1, blackRacing3000.gallery2,
            blackRacing3000.gallery3, blackRacing3000.gallery4
        ],
        preview: blackRacing3000.preview,
        price: '49,000.00',
        images: [ blackRacing3000.side, blackRacing3000.back, blackRacing3000.front ],
        link: 'black-racing-3000',
        name: 'Black Racing 3000',
        type: 'sport',
        specs: [
            '90km de autonomía promedio',
            '80km/h velocidad máxima',
            'Carga Completa en 8 horas',
            'Batería de 350 Ciclos de Carga',
            'Tipo de batería: Batería de ácido',
            'Frenos CSB Doble Disco',
            'Vida de la batería de 350 ciclos de carga',
            'Cuadro hecho de acero',
            'Peso 169 kg con batería',
            'Carga de peso máxima 250kg',
        ],
        visitCover: blackRacing5000.visit,
        batteries: [
            { 
                id: '0', 
                name: 'Sin Batería',
                description: 'Puedes adquirir la moto sin batería.',
                price: 49600,
                difference: 0,
                autonomy: '0 km',
            }, 
            { 
                id: '1', 
                name: '72v40apm',
                description: 'Batería de acido de 72 volts con 40 amperes.',
                difference: 21600.00,
                autonomy: '60 km',
            }, 
            { 
                id: '2', 
                name: '72v30apm',
                description: 'Batería de acido de 72 volts con 30 amperes.',
                difference: 21150.00,
                autonomy: '50 km',
            }, 
            { 
                id: '3', 
                name: '72v40apm',
                description: 'Batería de litio de 72 volts con 40 amperes.',
                difference: 28200.00,
                autonomy: '60 km',
            },
            { 
                id: '4', 
                name: '72v60apm',
                description: 'Batería de litio de 72 volts con 60 amperes.',
                difference: 42300.00,
                autonomy: '100 km',
            },
            { 
                id: '5', 
                name: '72v80apm',
                description: 'Batería de litio de 72 volts con 80 amperes.',
                difference: 56400.00,
                autonomy: '120 km',
            },
        ],
    },
    "black-racing-5000": {
        bikeInfo: 'https://motoelectrics.com/bike-info/Black%20Racing.pdf',
        brand: 'Sinotech',
        buyLink: 'https://motoelectrics.com/tienda/producto/black-racing-5000w/',
        colors: ['#000000', '#666666'],
        cover: blackRacing5000.cover,
        gallery: [ 
            blackRacing5000.gallery1, blackRacing5000.gallery2, blackRacing5000.gallery3,
            blackRacing5000.gallery4, blackRacing5000.gallery5, blackRacing5000.gallery6
        ],
        preview: blackRacing3000.preview,
        images: [ blackRacing3000.side, blackRacing3000.back, blackRacing3000.front ],
        link: 'black-racing-5000',
        name: 'Black Racing 5000',
        price: '57,000.00',
        type: 'sport',
        specs: [
            '120km de autonomía promedio',
            '100-120km/h velocidad máxima',
            'Carga Completa en 6 horas',
            'Tipo de batería: Batería de Litio 75V50Ah',
            'Frenos CSB Doble Disco',
            'Vida de la batería de 1050 ciclos de carga',
            'Cuadro hecho de acero',
            'Peso 149kg con batería',
            'Carga de peso máxima 250kg'
        ],
        visitCover: blackRacing5000.visit,
        batteries: [
            {
                id: '0',
                name: 'Sin Batería',
                description: 'Puedes adquirir la moto sin batería.',
                price: 61400,
                difference: 0,
                autonomy: '0 km',
            },
            // {
            //     id: '1',
            //     name: '72v40apm',
            //     description: 'Batería de acido de 72 volts con 40 amperes.',
            //     difference: 21600.00,
            //     autonomy: '',
            // },
            // {
            //     id: '2',
            //     name: '72v30apm',
            //     description: 'Batería de acido de 72 volts con 30 amperes.',
            //     difference: 21150.00,
            //     autonomy: '',
            // },
            {
                id: '1',
                name: '72v40apm',
                description: 'Batería de litio de 72 volts con 40 amperes.',
                difference: 28200.00,
                autonomy: '60 km',
            },
            {
                id: '2',
                name: '72v60apm',
                description: 'Batería de litio de 72 volts con 60 amperes.',
                difference: 42300.00,
                autonomy: '100 km',
            },
            {
                id: '3',
                name: '72v80apm',
                description: 'Batería de litio de 72 volts con 80 amperes.',
                difference: 56400.00,
                autonomy: '120 km',
            },
        ],
    },
    "white-racing": {
        bikeInfo: 'https://motoelectrics.com/bike-info/White%20Racing.pdf',
        brand: 'Sinotech',
        buyLink: 'https://motoelectrics.com/tienda/producto/white-racing/',
        colors: ['#ffffff'],
        cover: whiteRacing.cover,
        gallery: [
            whiteRacing.gallery1, whiteRacing.gallery2, whiteRacing.gallery3,
            whiteRacing.gallery4, whiteRacing.gallery5, whiteRacing.gallery6,
        ],
        preview: whiteRacing.preview,
        images: [ whiteRacing.side, whiteRacing.back, whiteRacing.front ],
        link: 'white-racing',
        name: 'White Racing',
        price: '49,000.00',
        type: 'sport',
        specs: [
            'Motor de 3000W',
            '90km de autonomía promedio',
            '80km/h velocidad máxima',
            'Carga Completa en 8 horas',
            'Peso de 168kg con batería',
            'Suspensión Hidráulica',
            'Tipo de batería: Batería de ácido',
            'Frenos de CBS de Disco Doble',
            'Vida de la batería de 350 ciclos de carga',
            'Cuadro hecho de acero',
            'Carga de peso máxima de 250kg',
        ],
        visitCover: whiteRacing.visit,
        batteries: [
            {
                id: '0',
                name: 'Sin Batería',
                description: 'Puedes adquirir la moto sin batería.',
                price: 49600,
                difference: 0,
                autonomy: '0 km',
            },
            {
                id: '1',
                name: '72v40apm',
                description: 'Batería de acido de 72 volts con 40 amperes.',
                difference: 21600.00,
                autonomy: '60 km',
            },
            {
                id: '2',
                name: '72v30apm',
                description: 'Batería de acido de 72 volts con 30 amperes.',
                difference: 21150.00,
                autonomy: '50 km',
            },
            {
                id: '3',
                name: '72v40apm',
                description: 'Batería de litio de 72 volts con 40 amperes.',
                difference: 28200.00,
                autonomy: '60 km',
            },
            {
                id: '4',
                name: '72v60apm',
                description: 'Batería de litio de 72 volts con 60 amperes.',
                difference: 42300.00,
                autonomy: '100 km',
            },
            {
                id: '5',
                name: '72v80apm',
                description: 'Batería de litio de 72 volts con 80 amperes.',
                difference: 56400.00,
                autonomy: '120 km',
            },
        ],
    },
    "st-2000": {
        bikeInfo: 'https://motoelectrics.com/bike-info/ST2000.pdf',
        brand: 'Sinotech',
        buyLink: 'https://motoelectrics.com/tienda/producto/st-2000/',
        colors: [ '#ffffff', '#000000', '#9e9e9e'],
        cover: st2000.cover,
        gallery: [
            st2000.gallery1, st2000.gallery2, st2000.gallery3,
            st2000.gallery4, st2000.gallery5, st2000.gallery6,
        ],
        preview: st2000.preview,
        images: [st2000.side, st2000.back, st2000.front],
        link: 'st-2000',
        name: 'ST 2000',
        price: '36,500.00',
        type: 'urban',
        specs: [
            'Motor de 2000W',
            '60km de autonomía promedio',
            '80km/h velocidad máxima',
            'Carga Completa en 6-8 horas',
            'Carga de peso máxima de 150kg',
            'Peso sin batería 70kg',
            'Peso de la batería 28kg',
            'Tipo de batería: Batería de ácido',
            'Freno delantero de Disco',
            'Freno trasero de Tambor',
        ],
        visitCover: st2000.visit,
        batteries: [
            {
                id: '0',
                name: 'Sin Batería',
                description: 'Puedes adquirir la moto sin batería.',
                price: 42100,
                difference: 0,
                autonomy: '0 km',
            },
            {
                id: '1',
                name: '72v40apm',
                description: 'Batería de acido de 72 volts con 40 amperes.',
                difference: 21600.00,
                autonomy: '80 km',
            },
            {
                id: '2',
                name: '72v30apm',
                description: 'Batería de acido de 72 volts con 30 amperes.',
                difference: 21150.00,
                autonomy: '60 km',
            },
            {
                id: '3',
                name: '72v40apm',
                description: 'Batería de litio de 72 volts con 40 amperes.',
                difference: 28200.00,
                autonomy: '80 km',
            },
            {
                id: '4',
                name: '72v60apm',
                description: 'Batería de litio de 72 volts con 60 amperes.',
                difference: 42300.00,
                autonomy: '120 km',
            },
            {
                id: '5',
                name: '72v80apm',
                description: 'Batería de litio de 72 volts con 80 amperes.',
                difference: 56400.00,
                autonomy: '160 km',
            },
        ],
    },
    "st-1000": {
        bikeInfo: 'https://motoelectrics.com/bike-info/ST1000.pdf',
        brand: 'Sinotech',
        buyLink: 'https://motoelectrics.com/tienda/producto/st-1000/',
        colors: [ '#000000', '#ffffff', '#9e9e9e'],
        cover: st1000.cover,
        gallery: [
            st1000.gallery1, st1000.gallery2, st1000.gallery3,
            st1000.gallery4, st1000.gallery5, st1000.gallery6,
        ],
        preview: st1000.preview,
        images: [st1000.side, st1000.back, st1000.front],
        link: 'st-1000',
        name: 'ST 1000',
        price: '21,000.00',
        type: 'urban',
        specs: [
            'Motor de 1000W',
            '65km de autonomía promedio',
            '60km/h velocidad máxima',
            'Carga Completa en 6-8 horas',
            'Carga de peso máxima de 150kg',
            'Peso Neto 70kg',
            'Peso sin batería 50.5kg',
            'Peso de la batería 28kg',
            'Tipo de batería: Batería de ácido',
            'Freno delantero de Disco',
            'Freno trasero de Tambor',
            'Entrada del cargador 110/220V',
            'Distancia entre ejes 1240mm',
        ],
        visitCover: st1000.visit,
        batteries: [
            {
                id: '0',
                name: 'Sin Batería',
                description: 'Puedes adquirir la moto sin batería.',
                price: 34200,
                difference: 0,
                autonomy: '0 km',
            },
            {
                id: '1',
                name: '72v40apm',
                description: 'Batería de acido de 72 volts con 40 amperes.',
                difference: 21600.00,
                autonomy: '90 km',
            },
            {
                id: '2',
                name: '72v30apm',
                description: 'Batería de acido de 72 volts con 30 amperes.',
                difference: 21150.00,
                autonomy: '75 km',
            },
            {
                id: '3',
                name: '72v40apm',
                description: 'Batería de litio de 72 volts con 40 amperes.',
                difference: 28200.00,
                autonomy: '90 km',
            },
            {
                id: '4',
                name: '72v60apm',
                description: 'Batería de litio de 72 volts con 60 amperes.',
                difference: 42300.00,
                autonomy: '150 km',
            },
            {
                id: '5',
                name: '72v80apm',
                description: 'Batería de litio de 72 volts con 80 amperes.',
                difference: 56400.00,
                autonomy: '180 km',
            },
        ],
    },
    "electric-delivery": {
        bikeInfo: 'https://motoelectrics.com/bike-info/Electric%20Delivery.pdf',
        brand: 'Sinotech',
        buyLink: 'https://motoelectrics.com/tienda/producto/electric-delivery/',
        colors: [ '#ffffff' ],
        cover: electricDelivery.cover,
        gallery: [
            electricDelivery.gallery1, electricDelivery.gallery2, electricDelivery.gallery3,
            electricDelivery.gallery4,
        ],
        preview: electricDelivery.preview,
        images: [ electricDelivery.side, electricDelivery.back, electricDelivery.back2, electricDelivery.front ],
        link: 'electric-delivery',
        name: 'Electric Delivery',
        price: '47,400',
        type: 'urban',
        specs: [
            'Motor de 2000W',
            '75km de autonomía promedio',
            '65km/h velocidad máxima',
            'Carga Completa en 8 horas',
            'Carga máxima de 160kg + mercancía',
            'Tipo de batería: Batería de ácido',
            'Freno delantero de Disco',
            'Freno trasero de Tambor',
            'Vida de la batería de 350 ciclos de carga',
            'Cuadro hecho de Acero',
            'Suspensión Hidráulica',
        ],
        visitCover: electricDelivery.visit,
        batteries: [
            {
                id: '0',
                name: 'Sin Batería',
                description: 'Puedes adquirir la moto sin batería.',
                price: 49000,
                difference: 0,
                autonomy: '0 km',
            },
            {
                id: '1',
                name: '72v40apm',
                description: 'Batería de acido de 72 volts con 40 amperes.',
                difference: 21600.00,
                autonomy: '60 km',
            },
            {
                id: '2',
                name: '72v30apm',
                description: 'Batería de acido de 72 volts con 30 amperes.',
                difference: 21150.00,
                autonomy: '50 km',
            },
            {
                id: '3',
                name: '72v40apm',
                description: 'Batería de litio de 72 volts con 40 amperes.',
                difference: 28200.00,
                autonomy: '60 km',
            },
            {
                id: '4',
                name: '72v60apm',
                description: 'Batería de litio de 72 volts con 60 amperes.',
                difference: 42300.00,
                autonomy: '100 km',
            },
            {
                id: '5',
                name: '72v80apm',
                description: 'Batería de litio de 72 volts con 80 amperes.',
                difference: 56400.00,
                autonomy: '120 km',
            },
        ],
    },
    "proximamente-1": {
        preview: comingSoon.comingSoon1,
        type: 'urban',
        comingSoon: true,
        batteries: [
            {
                id: '0',
                name: 'Sin Batería',
                description: 'Puedes adquirir la moto sin batería.',
                price: 62800,
                difference: 0,
                autonomy: '0 km',
            },
            {
                id: '1',
                name: '72v40apm',
                description: 'Batería de acido de 72 volts con 40 amperes.',
                difference: 21600.00,
                autonomy: '60 km',
            },
            {
                id: '2',
                name: '72v30apm',
                description: 'Batería de acido de 72 volts con 30 amperes.',
                difference: 21150.00,
                autonomy: '50 km',
            },
            {
                id: '3',
                name: '72v40apm',
                description: 'Batería de litio de 72 volts con 40 amperes.',
                difference: 28200.00,
                autonomy: '60 km',
            },
            {
                id: '4',
                name: '72v60apm',
                description: 'Batería de litio de 72 volts con 60 amperes.',
                difference: 42300.00,
                autonomy: '100 km',
            },
            {
                id: '5',
                name: '72v80apm',
                description: 'Batería de litio de 72 volts con 80 amperes.',
                difference: 56400.00,
                autonomy: '120 km',
            },
        ],
    },
    "xiaomi-scooter": {
        brand: 'Xiaomi',
        buyLink: 'https://motoelectrics.com/tienda/producto/scooter-xiaomi/',
        colors: [ '#000000' ],
        cover: xiaomiEssentials.cover,
        gallery: [
            xiaomiEssentials.gallery1, xiaomiEssentials.gallery2, xiaomiEssentials.gallery3
        ],
        preview: xiaomiEssentials.preview,
        images: [ xiaomiEssentials.side, xiaomiEssentials.back, xiaomiEssentials.back2, xiaomiEssentials.front ],
        link: 'xiaomi-scooter',
        name: 'Xiaomi Scooter',
        price: 13500,
        type: 'scooter',
        specs: [
            '20km de autonomía promedio',
            '25km/h velocidad máxima',
            'Carga Completa en 5 horas',
            'Hasta 5 velocidades en marcha',
            'Carga máxima de 100kg',
            'Faros ultrabrillantes para la conducción nocturna',
            'Pantalla integrada para monitorear tu viaje de manera cómoda',
            'Peso aproximado de 12.2kg',
            'Edad recomendada de 16 a 50 años',
            'Ángulo de inclinación máxima de 14%',
            'Dimensiones 108 x 43 x 114 cm',
            'Cuadro hecho de acero',
            '18km/h velocidad máxima en modo ahorro de energía',
        ],
        visitCover: xiaomiEssentials.visit,
        batteries: [],
    },
    "elegate-scooter": {
        brand: 'Ele-Gate',
        buyLink: 'https://motoelectrics.com/tienda/producto/electric-scooter/',
        colors: [ '#000000' ],
        cover: xiaomiEssentials.gallery3,
        gallery: [
            xiaomiEssentials.gallery1, xiaomiEssentials.gallery2,
        ],
        preview: xiaomiEssentials.preview,
        images: [ xiaomiEssentials.side, xiaomiEssentials.back, xiaomiEssentials.back2, xiaomiEssentials.front ],
        link: 'elegate-scooter',
        name: 'Ele-Gate Scooter',
        price: 10500,
        type: 'scooter',
        specs: [
            '20km de autonomía promedio',
            '25km/h velocidad máxima',
            'Carga Completa en 5 horas',
            'Hasta 5 velocidades en marcha',
            'Carga máxima de 100kg',
            'Faros ultrabrillantes para la conducción nocturna',
            'Pantalla integrada para monitorear tu viaje de manera cómoda',
            'Peso aproximado de 12.2kg',
            'Edad recomendada de 16 a 50 años',
            'Ángulo de inclinación máxima de 14%',
            'Dimensiones 108 x 43 x 114 cm',
            'Cuadro hecho de acero',
            '18km/h velocidad máxima en modo ahorro de energía',
        ],
        visitCover: xiaomiEssentials.visit,
        batteries: [],
    },
}