import React, { useState } from 'react'
import { ProductLink } from '../ProductLink'
import { SectionTitle } from '../UI/SectionTitle'
import { products } from '../../db/products';

export const HomeProducts = () => {

    const [ sports, setSports ] = useState([]);
    const [ urban, setUrban ] = useState([]);
    const [ scooters, setScooters ] = useState([]);

    const generateProducts = () => {
        const sportsBikes = [];
        const urbanBikes = [];
        const electricScooters = [];
        Object.values( products ).forEach((product, index, array) => {
            if( product.type === 'sport' ) {
                sportsBikes.push( product );
            }
            if( product.type === 'urban' ) {
                urbanBikes.push(product);
            }
            if( product.type === 'scooter' ) {
                electricScooters.push(product);
            }
            if( index === array.length - 1 ) {
                setSports([ ...sportsBikes ]);
                setUrban([ ...urbanBikes ]);
                setScooters([ ...electricScooters ]);
            }

        })
    }

    React.useEffect(() => {
        generateProducts();
    }, []);

    return (
        <div className = ' mx-0 py-5 bg-light '>
            <section className = ' py-5 '>
                <SectionTitle 
                    title = 'DEPORTIVIDAD ELÉCTRICA'
                    subtitle = 'ESPÍRITU DEPORTIVO'
                    id = 'sports'
                />
                <div className = ' row mx-0 px-2 px-md-5 pt-5 '>
                    { sports.map((bike, index) => (
                        <ProductLink 
                            image = { bike.preview }
                            link  = { bike.link }
                            name  = { bike.name }
                            price = { bike.price }
                            key = { index }
                            comingSoon = { bike.comingSoon }
                        />
                    )) }
                </div>
            </section>
            <section className = ' pb-5 '>
                <SectionTitle 
                    title = 'MOVILIDAD URBANA'
                    subtitle = 'PRACTICIDAD DIARIA'
                    id = 'urban'
                />
                <div className = ' row mx-0 px-2 px-md-5 pt-5 '>
                    { urban.map((bike, index) => (
                        <ProductLink 
                            image = { bike.preview }
                            link  = { bike.link }
                            name  = { bike.name }
                            price = { bike.price }
                            key = { index }
                            comingSoon = { bike.comingSoon }
                        />
                    )) }
                </div>
            </section>
            <section className = ' pb-5 '>
                <SectionTitle 
                    title = 'MOVILIDAD PERSONAL'
                    subtitle = 'SCOOTERS ELÉCTRICOS'
                    id = 'scooters'
                />
                <div className = ' row mx-0 px-2 px-md-5 pt-5 '>
                    { scooters.map((bike, index) => (
                        <ProductLink 
                            image = { bike.preview }
                            link  = { bike.link }
                            name  = { bike.name }
                            price = { bike.price }
                            key = { index }
                            comingSoon = { bike.comingSoon }
                        />
                    )) }
                </div>
            </section>
        </div>
    )
}
