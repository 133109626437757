import React from 'react'
import { useState } from 'react';

import gallery1 from '../../assets/reviews-gallery/gallery-1.jpeg';
import gallery2 from '../../assets/reviews-gallery/gallery-2.jpeg';
import gallery3 from '../../assets/reviews-gallery/gallery-3.jpeg';
import gallery4 from '../../assets/reviews-gallery/gallery-4.jpeg';
import gallery5 from '../../assets/reviews-gallery/gallery-5.jpeg';
import gallery6 from '../../assets/reviews-gallery/gallery-6.jpeg';

export const ReviewsGallery = () => {

    const [isSelected, setIsSelected] = useState(false)
    const [selectedImg, setSelectedImg] = useState('');
    const [caption, setCaption] = useState('');

    const selectImage = (image, altText) => {
        setSelectedImg(image)
        setIsSelected(true)
        setCaption(altText)
    }

    return (
        <div>

            <div className = "galeria" id = "galeria">
                <h3 className = 'mb-3'>GALERÍA</h3>

                <div className = "gallery-content">

                    <div className = "gallery-row">
                        <img id = "gallery-1" src = { gallery1 } alt="Black Racing" onClick = { () => selectImage(gallery1, 'Black Racing') }/>
                        <img id = "gallery-2" src = { gallery2 } alt="White Racing" onClick = { () => selectImage(gallery2, 'White Racing') }/>
                        <img id = "gallery-3" src = { gallery3 } alt="Black Racing / White Racing" onClick = { () => selectImage(gallery3, 'Black Racing / White Racing') }/>
                    </div>

                    <div className = "gallery-row">
                        <img id = "gallery-4" src = { gallery4 } alt="Black Racing" onClick = { () => selectImage(gallery4, 'Black Racing') }/>
                        <img id = "gallery-5" src = { gallery5 } alt="Black Racing" onClick = { () => selectImage(gallery5, 'Black Racing') }/>
                        <img id = "gallery-6" src = { gallery6 } alt="ST2000" onClick = { () => selectImage(gallery6, 'ST2000') }/>
                    </div>

                </div>
            </div>

            <div id = "myModal" className = "gallery-modal" style = {{ display: isSelected ? 'block' : 'none' }}>

                <span className = "close pt-5" onClick = { () => setIsSelected(false) }>&times;</span>
            
                <img className = "gallery-modal-content" style = {{ width: '100vw', height: '100vh', padding: '6rem 2rem', objectFit: 'contain', }} src = { selectedImg }/>
            
                <div id = "caption">{ caption }</div>
            
            </div>
        
        </div>

    )
}
