import React from 'react'

export const ProductGallery = ({ images }) => {

    // console.log( images );
    
    if( images.length > 0 ) {
        return (
            <div className = ' row mx-0 gallery_container '>
                { images.map((image, index) => (
                    <div className = ' col-12 col-sm-6 col-md-4 px-0 ' key = { index } >
                        <img className = ' gallery_image ' src = { image } />
                    </div>
                ))}
            </div>
        )
    }

    return (<></>);

}
