import React from 'react'

export const ProductDisplaySlider = ({ images }) => {
    return (
        <>
            <div id = "bikeDisplayCarousel" className = " carousel slide carousel-dark detail_carousel p-0 " data-bs-ride = "true">
                <div className = " carousel-indicators ">
                    { images.map((image, index) => (
                        <button 
                            type = ' button ' 
                            data-bs-target = " #bikeDisplayCarousel " 
                            data-bs-slide-to = { index } 
                            className = { index === 0 ? 'active' : ''} 
                            aria-current = {index === 0 ? 'true' : ''} 
                            aria-label = { index }
                            key = { index }
                        />

                    ))}
                </div>
                <div className ="carousel-inner">
                    { images.map((image, index) => (
                        <div key = { image } className = { 'carousel-item' + ((index === 0) ? ' active ' : ' ') }>
                            <div className= 'd-flex display-item'>
                                <img src = { image } className = "d-block w-100" style = {{objectFit: 'contain'}} alt = { index }/>
                            </div>
                        </div>
                    ))}
                </div>
                <button className = " carousel-control-prev" type="button" data-bs-target="#bikeDisplayCarousel" data-bs-slide="prev">
                    <span className = "carousel-control-prev-icon" aria-hidden="true"></span>
                    <span className = "visually-hidden">Previous</span>
                </button>
                <button className = "carousel-control-next" type="button" data-bs-target="#bikeDisplayCarousel" data-bs-slide="next">
                    <span className = "carousel-control-next-icon" aria-hidden="true"></span>
                    <span className = "visually-hidden">Next</span>
                </button>
            </div>
        </>
    )
}
