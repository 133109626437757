// Black Racing 3000
import brPreview from '../assets/bikes/blackracing-3000/br-preview.png';
import br3000Cover from '../assets/bikes/blackracing-3000/black-racing.jpeg';
import brSide from '../assets/bikes/blackracing-3000/side.png';
import brFront from '../assets/bikes/blackracing-3000/front.png';
import brBack from '../assets/bikes/blackracing-3000/back.png';
import br3000gall1 from '../assets/bikes/blackracing-3000/gallery-1.png';
import br3000gall2 from '../assets/bikes/blackracing-3000/gallery-2.png';
import br3000gall3 from '../assets/bikes/blackracing-3000/gallery-3.png';
import br3000gall4 from '../assets/bikes/blackracing-3000/gallery-4.png';
import br3000Visit from '../assets/bikes/blackracing-3000/producto-cover.jpg';

// Black Racing 5000
import br5000Cover from './../assets/bikes/blackracing-5000/br-cover.jpeg';
// import br5000gall1 from '../assets/bikes/blackracing-5000/gallery-1.png';
import br5000gall1 from './../assets/bikes/blackracing-5000/gallery-1.png';
import br5000gall2 from '../assets/bikes/blackracing-5000/gallery-2.png';
import br5000gall3 from '../assets/bikes/blackracing-5000/gallery-3.png';
import br5000gall4 from '../assets/bikes/blackracing-5000/gallery-4.jpeg';
import br5000gall5 from '../assets/bikes/blackracing-5000/gallery-5.jpeg';
import br5000gall6 from '../assets/bikes/blackracing-5000/gallery-6.jpeg';
import br5000Visit from '../assets/bikes/blackracing-5000/gallery-3.png';

// White Racing
import wrPreview from '../assets/bikes/white-racing/wr-preview.png';
import wrCover from '../assets/bikes/white-racing/wr-cover.jpeg';
import wrSide from '../assets/bikes/white-racing/cover.png';
import wrFront from '../assets/bikes/white-racing/frente.png';
import wrBack from '../assets/bikes/white-racing/trasera.png';
import wrGall1 from '../assets/bikes/white-racing/gallery-1.png';
import wrGall2 from '../assets/bikes/white-racing/gallery-3.png';
import wrGall3 from '../assets/bikes/white-racing/gallery-4.png';
import wrGall4 from '../assets/bikes/white-racing/gallery-5.png';
import wrGall5 from '../assets/bikes/white-racing/gallery-6.jpeg';
import wrGall6 from '../assets/bikes/white-racing/gallery-7.jpeg';
import wrVisit from '../assets/bikes/white-racing/buy-now.jpeg';

// St2000
import st2000Preview from '../assets/bikes/st2000/st-2000-preview.png';
import st2000Cover from '../assets/bikes/st2000/cover.jpg';
import st2000Side from '../assets/bikes/st2000/ST2000_lado.png';
import st2000Front from '../assets/bikes/st2000/frente.png';
import st2000Back from '../assets/bikes/st2000/trasera.png';
import st2000Gall1 from '../assets/bikes/st2000/gallery-1.jpeg';
import st2000Gall2 from '../assets/bikes/st2000/gallery-3.jpeg';
import st2000Gall3 from '../assets/bikes/st2000/gallery-3.jpeg';
import st2000Gall4 from '../assets/bikes/st2000/gallery-4.png';
import st2000Gall5 from '../assets/bikes/st2000/gallery-5.jpeg';
import st2000Gall6 from '../assets/bikes/st2000/gallery-6.jpeg';
import st2000Visit from '../assets/bikes/st2000/gallery-2.jpeg';

// St1000
import st1000Preview from '../assets/bikes/st1000/st-1000.png';
import st1000Cover from '../assets/bikes/st1000/st1000cover.jpeg';
import st1000Side from '../assets/bikes/st1000/side.png';
import st1000Front from '../assets/bikes/st1000/frente.png';
import st1000Back from '../assets/bikes/st1000/trasera.png';
import st1000Gall1 from '../assets/bikes/st1000/gallery-1.png';
import st1000Gall2 from '../assets/bikes/st1000/gallery-3.png';
import st1000Gall3 from '../assets/bikes/st1000/gallery-3.png';
import st1000Gall4 from '../assets/bikes/st1000/gallery-4.png';
import st1000Gall5 from '../assets/bikes/st1000/gallery-5.png';
import st1000Gall6 from '../assets/bikes/st1000/gallery-6.png';
import st1000Visit from '../assets/bikes/st1000/gallery-2.png';

// Electric Delivery
import eDeliveryPreview from '../assets/bikes/electric-delivery/electric-delivery.png';
import eDeliveryCover from '../assets/bikes/electric-delivery/electric-cover.jpg';
import eDeliverySide from '../assets/bikes/electric-delivery/side.png';
import eDeliveryFront from '../assets/bikes/electric-delivery/frente.png';
import eDeliveryBack1 from '../assets/bikes/electric-delivery/trasera1.png';
import eDeliveryBack2 from '../assets/bikes/electric-delivery/trasera2.png';
import eDeliveryGall1 from '../assets/bikes/electric-delivery/gallery-1.jpg';
import eDeliveryGall2 from '../assets/bikes/electric-delivery/gallery-3.jpg';
import eDeliveryGall3 from '../assets/bikes/electric-delivery/gallery-3.jpg';
import eDeliveryGall4 from '../assets/bikes/electric-delivery/gallery-4.jpg';
import eDeliveryVisit from '../assets/bikes/electric-delivery/gallery-3.jpg';

// Electric Delivery
import xiaomiEssPreview from '../assets/bikes/xiaomi-essentials/xiaomi-scooter.png';
import xiaomiEssCover from '../assets/bikes/xiaomi-essentials/cover.jpeg';
import xiaomiEssSide from '../assets/bikes/xiaomi-essentials/mi-2.png';
import xiaomiEssFront from '../assets/bikes/xiaomi-essentials/mi-5.png';
import xiaomiEssBack1 from '../assets/bikes/xiaomi-essentials/mi-8.png';
import xiaomiEssBack2 from '../assets/bikes/xiaomi-essentials/mi-1.png';
import xiaomiEssGall1 from '../assets/bikes/xiaomi-essentials/catalog-cover.jpeg';
import xiaomiEssGall2 from '../assets/bikes/xiaomi-essentials/img-1.jpeg';
import xiaomiEssGall3 from '../assets/bikes/xiaomi-essentials/mi-detail-2.jpeg';
import xiaomiEssVisit from '../assets/bikes/xiaomi-essentials/mi-detail-1.jpeg';

// Coming Soon
import comingSoon1 from '../assets/bikes/comingSoon/comingSoon.png';

// Sport Bikes

const blackRacing3000 = {
    preview: brPreview,
    cover: br3000Cover,
    side: brSide,
    front: brFront,
    back: brBack,
    gallery1: br3000gall1,
    gallery2: br3000gall2,
    gallery3: br3000gall3,
    gallery4: br3000gall4,
    visit: br3000Visit,
}

const blackRacing5000 = {
    cover: br5000Cover,
    gallery1: br5000gall1,
    gallery2: br5000gall2,
    gallery3: br5000gall3,
    gallery4: br5000gall4,
    gallery5: br5000gall5,
    gallery6: br5000gall6,
    visit: br5000Visit,
}

const whiteRacing = {
    preview: wrPreview,
    cover: wrCover,
    side: wrSide,
    front: wrFront,
    back: wrBack,
    gallery1: wrGall1,
    gallery2: wrGall2,
    gallery3: wrGall3,
    gallery4: wrGall4,
    gallery5: wrGall5,
    gallery6: wrGall6,
    visit: wrVisit
}


// Urban Bikes

const st2000 = {
    preview: st2000Preview,
    cover: st2000Cover,
    side: st2000Side,
    front: st2000Front,
    back: st2000Back,
    gallery1: st2000Gall1,
    gallery2: st2000Gall2,
    gallery3: st2000Gall3,
    gallery4: st2000Gall4,
    gallery5: st2000Gall5,
    gallery6: st2000Gall6,
    visit: st2000Visit
}

const st1000 = {
    preview: st1000Preview,
    cover: st1000Cover,
    side: st1000Side,
    front: st1000Front,
    back: st1000Back,
    gallery1: st1000Gall1,
    gallery2: st1000Gall2,
    gallery3: st1000Gall3,
    gallery4: st1000Gall4,
    gallery5: st1000Gall5,
    gallery6: st1000Gall6,
    visit: st1000Visit
}

const electricDelivery = {
    preview: eDeliveryPreview,
    cover: eDeliveryCover,
    side: eDeliverySide,
    front: eDeliveryFront,
    back: eDeliveryBack1,
    back2: eDeliveryBack2,
    gallery1: eDeliveryGall1,
    gallery2: eDeliveryGall2,
    gallery3: eDeliveryGall3,
    gallery4: eDeliveryGall4,
    visit: eDeliveryVisit
}

// Electric Scooters

const xiaomiEssentials = {
    preview: xiaomiEssPreview,
    cover: xiaomiEssCover,
    side: xiaomiEssSide,
    front: xiaomiEssFront,
    back: xiaomiEssBack1,
    back2: xiaomiEssBack2,
    gallery1: xiaomiEssGall1,
    gallery2: xiaomiEssGall2,
    gallery3: xiaomiEssGall3,
    visit: xiaomiEssVisit
}

// Coming Soon 

const comingSoon = {
    comingSoon1: comingSoon1,
}

export {
    blackRacing3000,
    blackRacing5000,
    whiteRacing,
    st2000,
    st1000,
    electricDelivery,
    xiaomiEssentials,
    comingSoon
}
