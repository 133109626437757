import React from 'react';
import { PrimaryButton } from '../UI/PrimaryButton';
import { WhiteButton } from '../UI/WhiteButton';

// Assets import.
import logo from './../../assets/logo.png';

export const HomeHeader = ({ image }) => {
    return (
        <div 
            className = ' home_header '
            style = {{ backgroundImage: `url(${ image })` }}
        >
            <div className = ' row mx-0 '>
                <div className = ' col-xs-12 col-md-7 col-lg-5 col-xl-4 offset-md-1 home_header-content '>
                    <img 
                        alt = 'Logo Motoelectrics.'
                        className = ' home_header-logo mb-5 '
                        src = { logo }
                    />
                    <div>
                        <h2 className = ' h1 text-center text-md-start '>TU MEJOR OPCIÓN EN MOVILIDAD</h2>
                        <div className = ' mt-5 mt-md-3 d-flex justify-content-center justify-content-md-start '>
                            <PrimaryButton link = '#' text = 'MODELOS' />
                            <div className = ' mx-2 '/>
                            <WhiteButton link = 'https://motoelectrics.com/tienda/' text = 'TIENDA' />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
