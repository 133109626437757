import React from 'react'
import { PrimaryButton } from '../UI/PrimaryButton'

export const YoutubeCarousel = () => {
    return (
        <div id = "youtubeCaruosel" className = "carousel slide carousel_content" data-bs-ride = "true">

            <div className = "carousel-indicators pb-3">
                <button type = "button" data-bs-target = "#youtubeCaruosel" data-bs-slide-to = "0" className = "active" aria-current = "true" aria-label = "MAX Miku"></button>
                <button type = "button" data-bs-target = "#youtubeCaruosel" data-bs-slide-to = "1" aria-label = "SUNRA"></button>
                <button type = "button" data-bs-target = "#youtubeCaruosel" data-bs-slide-to = "2" aria-label = "Black Racing"></button>
                <button type = "button" data-bs-target = "#youtubeCaruosel" data-bs-slide-to = "3" aria-label = "Cargo"></button>
            </div>
            
            <div className = "carousel-inner reviews_videos_content">
                <div className = "carousel-item active">
                    <iframe
                        title = 'MAX Miku'
                        src = "https://www.youtube.com/embed/jlUalFz869g"
                        allow = "accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen>
                    </iframe>
                </div>
                <div className = "carousel-item">
                    <iframe
                        title='SUNRA'
                        src="https://www.youtube.com/embed/7XAxqfTBUnQ"
                        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen>
                    </iframe>
                </div>
                <div className ="carousel-item">
                    <iframe
                        title='Black Racing'
                        src="https://www.youtube.com/embed/qe-EQ6cL3dg"
                        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen>
                    </iframe>
                </div>
                <div className = "carousel-item">
                    <iframe
                        title='Cargo'
                        src="https://www.youtube.com/embed/3NOHRrK4V-E"
                        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen>
                    </iframe>
                </div>
            </div>

            <button className = "carousel-control-prev" type = "button" data-bs-target = "#youtubeCaruosel" data-bs-slide = "prev">
                <span className = "carousel-control-prev-icon" aria-hidden = "true"></span>
                <span className = "visually-hidden">Siguiente</span>
            </button>

            <button className = "carousel-control-next" type = "button" data-bs-target = "#youtubeCaruosel" data-bs-slide = "next">
                <span className = "carousel-control-next-icon" aria-hidden = "true"></span>
                <span className = "visually-hidden">Anterior</span>
            </button>

            {/* <div className = "mt-4">
                <PrimaryButton
                    text='Ver todos los videos'
                    link='https://www.youtube.com/channel/UC-FJKJ7ASep-d-Z4tL3ufSg'
                    external={true}
                />
            </div> */}

        </div>
    )
}
