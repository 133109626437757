import React from 'react'
import { Link } from 'react-router-dom'

export const WhiteButton = ({ text, link, external = false }) => {

    if( !external ) {
        return (
            <Link to = { link } className = {' button_white '}>
                { text }
            </Link>
        )
    }
    
    if( external ) {
        return (
            <a href = { link } className = {' button_white '}>
                { text }
            </a>
        )
    }
}