import React, { useState } from 'react'
import { useParams } from 'react-router-dom';
import { BatterySelection } from '../components/Detail/BikeCustomization/BatterySelection';
import { BikeSpecs } from '../components/Detail/BikeCustomization/BikeSpecs';
import { ProductBuyVisit } from '../components/Detail/ProductBuyVisit';
import { ProductDisplaySlider } from '../components/Detail/ProductDisplaySlider';
import { ProductGallery } from '../components/Detail/ProductGallery';
import { products } from '../db/products';
import { formatMoney } from '../utils/formatMoney';
import { ProductNotFound } from './ProductNotFound';

export const Detail = () => {
    
    const { product: id } = useParams();
    const [product, setProduct] = useState('');
    const [bikeOptions, setBikeOptions] = useState({
        battery: null,
        color: null,
    });

    const { batteries, bikeInfo, buyLink, cover, gallery, images, price, visitCover } = product;
    
    const getProductData = ( ) => {
        const currentProduct = products[id];
        console.log( currentProduct );
        if( currentProduct !== undefined ) {
            setProduct( currentProduct );
        } else {
            setProduct( 'not-found' );
        }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    React.useEffect(() => getProductData(), []);

    if( product === 'not-found' || product === '' ) {
        return (
            <ProductNotFound />
        )
    }

    return (
        <div className = ' pb-2 '>
            {/* Header Section  */}
            <section
                className = ' detail_header '
                style = {{
                    backgroundImage: `url( ${ cover })`,
                    height: '90vh',
                }}
                >
                <p className = ' detail_header-name text-center'>
                    { product.name }
                </p>
                <p className = ' detail_header-price '>
                    Adquiérela desde ${ price }
                </p>
            </section>
            {/* End Header Section  */}

            {/* Specs Section  */}
            <section className = '  px-3 px-md-5 py-5 '>
                <div className = ' row mx-0 detail_specs justify-content-center '>
                    <div className = 'detail_specs-customization col-12 col-md-4 p-2 p-md-5'>
                        <BatterySelection batteries = { batteries } bikeOptions = { bikeOptions } setBikeOptions = { setBikeOptions } />
                        <BikeSpecs product = { product } />
                    </div>
                    <div className = ' detail_specs-slider col-12 col-md-8 row px-0 px-md-4 '>
                        <div className = ' detail_top-data row d-flex align-items-center pb-3 pb-md-5 '>
                            <div className = ' col-12 col-md-6 ps-0'>
                                <p className = ' h3 mb-0 text-center text-md-start '>Tu { product.name }</p>  
                            </div>
                            { 
                                batteries && batteries.length > 0 && (
                                    <div className = ' col-12 col-md-6 d-flex justify-content-center justify-content-md-end pe-0 pe-md-4 text-center text-md-end' >
                                        {
                                            !!batteries[bikeOptions.battery-1] ? 
                                            (
                                                <div>
                                                    <p className = ' h4 mb-0 detail_final-price '>{ formatMoney(batteries[0].price + batteries[bikeOptions.battery].difference) }</p> 
                                                    <p className=' h5 mb-0 detail_autonomy '>Autonomía Promedio: { batteries[bikeOptions.battery].autonomy }</p>
                                                </div>
                                            )  
                                            :
                                            (
                                                <p className = ' h5 mb-0 detail_final-price '>{ formatMoney(batteries[0].price) }</p>
                                            )
                                        }
                                    </div>
                                )
                            }
                        </div>
                        <ProductDisplaySlider images = { images }/>
                    </div>
                </div>

                <div className = ' d-flex px-2 px-md-5 '>
                    <a href = { buyLink } className = ' detail_action-button ' target = '_blank' rel="noreferrer">
                        COMPRAR
                    </a>
                    {
                        !!bikeInfo && (
                        <div className = ' detail_action-button ms-2 '>
                            <a href = { bikeInfo } target = '_blank' className = ' mb-0 ' rel="noreferrer">DESCARGAR FICHA TÉCNICA</a>
                        </div>
                        )
                    }
                </div>
            </section>
            {/* End Specs Section  */}

            {/* Gallery Section  */}
            <section className = ' py-5 '>
                <ProductGallery images = { gallery } />
            </section>

            {/* Buy or Visit Section */}
            <section>
                <ProductBuyVisit cover = { visitCover } url = { buyLink }/>
            </section>
    
        </div>
    )
}
