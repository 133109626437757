import React from 'react'
import { useState } from 'react'

export const ReviewsFAQ = () => {

    const [activeQuestion, setActiveQuestion] = useState('q1')

    return (
        <div className = 'preguntas' id = 'preguntas'>
            <h3>PREGUNTAS FRECUENTES</h3>
            
            <div className = 'preguntas-content'>
                <div className = 'question-container'>
                    <p data-question="#q1" className = { activeQuestion === 'q1' ? 'active' : '' } onClick = { () => setActiveQuestion('q1') }>¿Es necesario disponer de una placa para circulación?</p>
                    <p data-question="#q2" className = { activeQuestion === 'q2' ? 'active' : '' } onClick = { () => setActiveQuestion('q2') }>¿Es caro el mantenimiento de una moto eléctrica?</p>
                    <p data-question="#q3" className = { activeQuestion === 'q3' ? 'active' : '' } onClick = { () => setActiveQuestion('q3') }>¿Cuánto duran cargadas las baterías?</p>
                    <p data-question="#q4" className = { activeQuestion === 'q4' ? 'active' : '' } onClick = { () => setActiveQuestion('q4') }>¿Qué mantenimiento y revisión se debe hacer a las motos eléctricas?</p>
                    <p data-question="#q5" className = { activeQuestion === 'q5' ? 'active' : '' } onClick = { () => setActiveQuestion('q5') }>¿Qué tipo de batería llevan las motos eléctricas?</p>
                    <p data-question="#q6" className = { activeQuestion === 'q6' ? 'active' : '' } onClick = { () => setActiveQuestion('q6') }>¿Qué vida útil tienen las baterías?</p>
                    <p data-question="#q7" className = { activeQuestion === 'q7' ? 'active' : '' } onClick = { () => setActiveQuestion('q7') }>¿Quién se encarga de la asistencia técnica en caso de daño y problemas?</p>
                </div>

                <div className = 'answer-container'>
                    <div data-answer id = "q1" className = { activeQuestion === 'q1' ? 'active' : '' }>
                        <p>
                            <strong>Sí</strong>, las motos eléctricas son como cualquier otro vehículo,
                            a diferencia que este opera con energía eléctrica, sin emisiones.
                        </p>
                    </div>
                    <div data-answer id = "q2" className = { activeQuestion === 'q2' ? 'active' : '' }>
                        <p>
                            Al ser más sencillas que las de motor de combustión, su mantenimiento
                            es más económico, y son menos proclives a las averías.
                        </p>
                    </div>
                    <div data-answer id="q3" className = { activeQuestion === 'q3' ? 'active' : '' }>
                        <p>La autonomía de las motos electricas está siempre condicionada por varios factores:</p>
                        <br/>
                        <ul className = 'me-3'>
                            <li>El peso transportado</li>
                            <li>La orografía del terreno</li>
                            <li>La forma de conducir</li>
                        </ul> 
                        <br/>
                        <p>
                            Lo usual es realizar entre 45 y 70 km con baterías de plomo y 500 cargas
                            (aproximadamente 25.000 km) y entre 70 y 120 km. en baterías de Ión-Litio
                            y entre 1.000 y 2.000 cargas (más de 70.000 km).
                        </p>

                    </div>
                    <div data-answer id="q4" className = { activeQuestion === 'q4' ? 'active' : '' }>
					    <p>
                            El motor no necesita ningún mantenimiento. <br/><br/>
                            El único mantenimiento es el de revisar
                            periódicamente el estado de las ruedas y frenos, como cualquier ciclomotor normal.</p>
                    </div>
                    <div data-answer id="q5" className = { activeQuestion === 'q5' ? 'active' : '' }>
                        <p>
                            Las baterías de las motos eléctricas suelen ser de litio, lo que implica que su 
                            comportamiento es mucho mejor que otro tipo de baterías en lo que se refiere 
                            al famoso “efecto memoria” que reduce su vida útil.
                            <br/><br/>
                            Por esta razón, las 
                            baterías se pueden cargar repetidamente varias veces, incluso en el mismo día.
                        </p>
                    </div>
                    <div data-answer id="q6" className = { activeQuestion === 'q6' ? 'active' : '' }>
                        <p>
                            Unos 500 ciclos completos, para baterías de plomo, y entre 1.000 y 
                            2.000 ciclos completos, para las baterías de Ión-Litio
                        </p>
                    </div>
                    <div data-answer id="q7" className = { activeQuestion === 'q7' ? 'active' : '' }>
                        <p>MotoElectrics</p>
                    </div>

                </div>
            </div>
        
        </div>
    )
}
