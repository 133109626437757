import React from 'react'
import { PrimaryButton } from './UI/PrimaryButton'

export const YoutubeLink = () => {
    return (
        <div className = ' py-5 p-3 p-md-5 row col-12 '>
            <div className = ' col-12 col-md-6 youtube_video_container '>
                <iframe 
                    width="100%" height="315" 
                    src = "https://www.youtube.com/embed/Lyd49kZF3H4" 
                    title = "Motoelectrics" frameBorder="0" 
                    allow = "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                    allowFullScreen
                ></iframe>
            </div>
            <div className = ' col-12 col-md-6 d-flex flex-column justify-content-center py-md-0 px-md-5 '>
                <p className = ' section-title '>SÍGUENOS EN NUESTRO CANAL DE YOUTUBE</p>
                <p>
                    Conoce nuestras novedades, y disfruta de nuestros modelos más a fondo, 
                    tenemos una opción perfecta para tí.
                </p>
                <PrimaryButton 
                    text = 'Ir a YouTube'
                    link = 'https://www.youtube.com/channel/UC-FJKJ7ASep-d-Z4tL3ufSg'
                    external = { true }
                />
            </div>
        </div>
    )
}
