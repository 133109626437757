import { Modal } from 'react-bootstrap';
import React, { useEffect } from 'react';
import { useState } from 'react';

export const SeasonModal = () => {

    const [show, setShow] = useState(false);

    useEffect(() => {
        const displayModal = setTimeout(() => {
            setShow(true)
        }, 3000);
        return () => clearTimeout(displayModal);
    }, [])

    return (
        <>
            <Modal 
                show = { show } 
                onHide = { () => setShow(false) }
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Dialog
                    id = "seasonModalToggle" 
                    aria-hidden = "true" 
                    aria-labelledby = "seasonModal" 
                    onClick = {() => setShow(false)}
                >
                    <Modal.Body
                        className = " modal-body d-flex justify-content-center "
                        style = {{ padding: 0 }}
                    >
                        {
                            show &&
                            (
                                <iframe
                                    id = 'youtubeVideo'
                                    width = "1000px" height="500px"
                                    src = "https://www.youtube.com/embed/j-z0ni1jt6U"
                                    title = "Motoelectrics" frameBorder="0"
                                    allow = "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture;"
                                    allowFullScreen
                                ></iframe>
                            )
                        }
                    </Modal.Body>
                </Modal.Dialog>
            </Modal>
        </>
    )
}
