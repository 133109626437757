import React from 'react';

import logo from '../assets/logo.png';
import facebook from '../assets/contact/facebook.svg';
import whatsapp from '../assets/contact/whatsapp.png';
import phone from '../assets/contact/phone.png';
import location from '../assets/contact/location.png';

export const Contact = () => {
    return (
        <div>
            <div className = "container py-5">
                <div className = "row d-flex align-items-center p-4 header">
                    <div className = "col-xs-12 col-md-6 text-center text-lg-start">
                        <h1>Contáctanos</h1>
                        <p>
                            ¿Te interesa hacer una compra o tener información extra de un modelo? No
                            dudes en contactarnos por cualquiera de nuestros medios, por teléfono o redes sociales.
                            ¡Además, puedes visitar nuestra tienda física en Durango, México para conocer personalmente
                            la moto de tus sueños!
                        </p>
                    </div>
                    <div className = "col-xs-12 col-md-6 d-flex justify-content-center order-first order-md-last">
                        <img
                            className = "logo mb-5 mb-md-0"
                            src = { logo } 
                        />
                    </div>
                </div>
            </div>

            <div className = "container">
                <div className = "row d-flex align-items-center p-4 header">
                    <div className = "col-xs-12 col-lg-6 mb-5 mb-lg-0">
                        <h4 className = "contact-title mb-3 mb-md-5">Contacto</h4>
                        <p className = "contact-item location">
                            <span><img src = { location } alt=""/></span>
                            Calle Aquiles Serdan 179 Pte, Zona Centro. Durango, Dgo.
                        </p>
                        <p className = "contact-item">
                            <span><img src = { whatsapp } alt=""/></span>
                            <a rel="noopener" href="https://wa.link/om8i8d">618 822 9177</a>
                        </p>
                        <p className = "contact-item">
                            <span><img src = { phone } alt=""/></span>
                            <a rel="noopener" href="tel:6188189252">618 818 9252</a>
                        </p>
                        <p className = "contact-item">
                            <span><img src = { facebook } alt=""/></span>
                            <a rel="noopener" href="https://www.facebook.com/motoelectrics.mx" target="_blank">MotoElectrics</a>
                        </p>
                    </div>
                    <div className = "col-xs-12 col-lg-6 d-flex justify-content-center">
                        <iframe
                            title = "Ubicación tienda en Durango"
                            className = "map"
                            src = "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d227.7569351771357!2d-104.6669112!3d24.0271519!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x869bc936f9a2bacd%3A0x7f6e3db26bf4bfbc!2sMotoelectrics!5e0!3m2!1ses-419!2smx!4v1664482933753!5m2!1ses-419!2smx"
                            style = {{ border: 'none' }}
                            allowFullScreen aria-hidden = "false"
                            tabIndex="0">
                        </iframe>
                    </div>
                </div>
            </div>
        </div>
    )
}
