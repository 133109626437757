import React from 'react'
import { Container, Nav, Navbar, NavDropdown } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import isotipo from './../assets/isotipo.png';

export const NavbarComponent = () => {

	const navigate = useNavigate();

    return (
        <div>
			<Navbar bg='light'  expand='lg' fixed='top'>
				<Container fluid>
					<Navbar.Brand href = "/inicio">
						<img 
							src = { isotipo } 
							alt = 'MotoElectrics' 
							width = { 30 } 
							height={ 24 } 
							className = ' navbar_logo d-inline-block align-text-top me-2 '
						/>
					</Navbar.Brand>
					<Navbar.Toggle aria-controls = 'basic-navbar-nav' aria-expanded = 'false' data-bs-toggle = 'collapse'/>
					<Navbar.Collapse id = 'basic-navbar-nav'>
						<Nav className = ' justify-content-end me-auto flex-grow-1 pe-3'>
							<Nav.Link onClick = {() => navigate('/inicio')}>Inicio</Nav.Link>
							<NavDropdown title = 'Modelos' id = 'basic-nav-dropdown'>
								<NavDropdown.Item href = '/#sports' onClick = {() => navigate('/#sports')}  >Motos Deportivas</NavDropdown.Item>
								<NavDropdown.Item href = '/#urban'  onClick = {() => navigate('/#urban')} >Motos Urbanas</NavDropdown.Item>
								<NavDropdown.Divider/>
								<NavDropdown.Item href = '/#scooters' onClick = {() => navigate('/#scooters')}  >Scooters</NavDropdown.Item>
							</NavDropdown>
							<Nav.Link href = 'https://motoelectrics.com/tienda/'>Tienda</Nav.Link>
							<Nav.Link onClick = {() => navigate('/testimonios')} >Testimonios</Nav.Link>
							<Nav.Link onClick = {() => navigate('/contacto')} >Contacto</Nav.Link>
						</Nav>
					</Navbar.Collapse>
				</Container>
			</Navbar>
			
		</div>
    )
}
